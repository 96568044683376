<template>
	<PageLayout :title="$t('update-password.title')">
		<div class="cashier-box">
			<div class="small-width">
				<CForm @submit.prevent="submitForm">
					<MaterialInput
						v-model="form.currentPassword.value"
						name="currentPassword"
						:error="form.currentPassword.error"
						type="password"
						:label="$t('update-password.current-password')"
						required
					/>

					<MaterialInput
						v-model="form.newPassword.value"
						name="newPassword"
						:error="form.newPassword.error"
						type="password"
						:label="$t('update-password.new-password')"
						required
					/>

					<MaterialInput
						v-model="form.repeatPassword.value"
						name="repeatPassword"
						:error="form.repeatPassword.error"
						type="password"
						:label="$t('update-password.repeat-password')"
						required
					/>

					<FormError :error="error" />

					<CButton type="submit" primary :loading="loading" :success="showSuccess">
						{{ $t('global.update') }}
					</CButton>
				</CForm>
			</div>
		</div>
	</PageLayout>
</template>

<script>
import { mapActions } from 'vuex';
import MaterialInput from '@/components/material-input/MaterialInput';
import CForm from '@/shared/cform/CForm';
import FormError from '@/shared/forms/FormError';
import CButton from '@/shared/cbutton/CButton';
import PageLayout from '@/components/page-layout/PageLayout';

export default {
	name: 'ChangePassword',
	components: {
		PageLayout,
		CButton,
		FormError,
		CForm,
		MaterialInput,
	},
	data() {
		return {
			success: false,
			loading: false,
			error: null,
			form: {
				currentPassword: {
					value: '',
					error: null,
				},
				newPassword: {
					value: null,
					error: null,
				},
				repeatPassword: {
					value: null,
					error: null,
				},
			},
			showSuccess: false,
		};
	},
	watch: {
		success(value) {
			if (value) {
				this.showSuccess = true;

				setTimeout(() => (this.showSuccess = false), 3500);
			}
		},
	},
	methods: {
		...mapActions(['updateUser']),
		async submitForm() {
			const { form } = this;
			this.clear();

			const hasErrors = Object.entries(form).filter((p) => p[1].error).length !== 0;
			if (!hasErrors) {
				this.loading = true;

				try {
					await this.updateUser({
						currentPassword: form.currentPassword.value,
						newPassword: form.newPassword.value,
						repeatPassword: form.repeatPassword.value,
					});
					this.success = true;
				} catch (e) {
					const { response } = e;
					if (!response) {
						console.log(e);
						return;
					}

					if (response.data.errors) {
						for (const error of response.data.errors) {
							const { param, msg } = error;
							form[param].error = msg;
						}
					}

					if (response.data.error && typeof response.data.error === 'string') {
						this.error = response.data.error;
					}
				} finally {
					this.loading = false;
				}
			}
		},
		clear() {
			for (const param in this.form) {
				this.form[param].error = '';
			}
			this.error = null;
			this.success = false;
		},
	},
};
</script>

<style scoped></style>
